<template>
    <a @click.prevent="$root.$children[0].openModal('kva-modal', row.item.offering_id)"
       href="#">{{ row.item.project }}</a>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>